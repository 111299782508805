import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import useProfile from "../../hooks/useProfile";
import { getUserToken } from "../../utils/authUtil";
import _ from "lodash";
import { PATH } from "../../constants/path";
import { Role } from "../../constants/role";

export interface ProtectedRouteInterface {
  redirectPath?: string;
  children?: any;
  onlyOwner?: boolean;
  factoryRoleCheck?: string[];
}

const ProtectedRoute = ({
  children,
  redirectPath = PATH.LOGIN,
  onlyOwner = false,
  factoryRoleCheck,
}: ProtectedRouteInterface) => {
  const { profile, getFactoryRole, loading } = useProfile();
  const user = getUserToken();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!_.isNull(profile) && !_.isUndefined(profile) && !loading) {
      let isAllowFactory: boolean = false;

      const userRoleId = profile.organizationRole?.id ?? 0;
      const factoryRole = getFactoryRole(Number(user?.selected_factory) ?? 0);
      const isOwner: boolean = Role.OWNER === userRoleId;

      if (!factoryRoleCheck) {
        isAllowFactory = true;
      } else {
        _.each(factoryRoleCheck, (role) => {
          const findRole = role === factoryRole;

          if (findRole) {
            isAllowFactory = true;
          }
        });
      }

      if (onlyOwner && !isOwner) {
        navigate(redirectPath);
      }

      if (!isAllowFactory && !isOwner) {
        // TODO : Uncomment if you need to display toast have no access page
        //toast.error(t("PAGE_NO_PERMISSION"));
        navigate(redirectPath);
      }
    }
  }, [
    factoryRoleCheck,
    getFactoryRole,
    loading,
    navigate,
    onlyOwner,
    profile,
    redirectPath,
    t,
    user?.selected_factory,
  ]);

  return children ? children : <Outlet />;
};

export default ProtectedRoute;
