import "../loader/loader.scss";

export interface LoaderProps {
    show: boolean
}
const Loading: React.FC<LoaderProps> = ({ show }) => {
    return (
        <div className={show ? "" : "d-none"}>
            <div className="container-loading  m-auto">

                <div className="background"></div>
                <div className="container-card" >
                    <div className="spinner-border text-primary mb-2" role="status">
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>

            </div>
        </div>
    );
};

export default Loading;
