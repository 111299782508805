import React from "react";
import { Breadcrumb } from "react-bootstrap";

export interface BreadcrumbProps {
  items: BreadcrumbItem[];
  children?: JSX.Element;
}
export interface BreadcrumbItem {
  active?: boolean;
  href?: string;
  label?: React.ReactNode;
  onClick?: () => void;
}
const BreadcrumbBox: React.FC<BreadcrumbProps> = ({ items, children }) => {
  const generateItem = () => {
    return items.map((item, index) => (
      <Breadcrumb.Item
        key={"bc-" + index}
        href={item.href}
        active={item.active}
        onClick={item.onClick}
      >
        {item.label}
      </Breadcrumb.Item>
    ));
  };

  return (
    <>
      <Breadcrumb>{generateItem()}</Breadcrumb>
      {children}
    </>
  );
};

export default BreadcrumbBox;
