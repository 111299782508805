import axios, {  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { PATH } from '../constants/path';
import { IAuthToken } from '../Interfaces/authToken';
import {  removeToken, saveRefreshToken, saveUserToken } from './authUtil';
import { getCurrentLanguage } from './languageUtil';


const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const setupInterceptorsTo = (
  axiosInstance: AxiosInstance
): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use((response) => {
      return response;
      },
       async (error) => {
        if (
          error.config &&
          error.response.status === 401 && // Use the status code your server returns when token has expired
          !error.config._retry
        ) {
          console.log("Access token 401");
          error.config._retry = true;
          try {
            const refreshTokenResult = await refreshToken(error.config);
            const { accessToken } = refreshTokenResult.data;          
            error.config.headers.Authorization = `Bearer ${accessToken}` ; 
            return instance().request(error.config); // Repeat the initial request
          }
          catch (error) {
            console.log('Refresh Token error => to login page')
            removeToken();
            window.location.href = PATH.LOGIN;
            return Promise.reject(error);
          }
        }
        else{
          return Promise.reject(error);
        }
      });
  return axiosInstance;
};

const instance = (auth:boolean = true) => { 

  const localStorageToken = window.localStorage.getItem('token');

  let culture = "en-US";
  const language = getCurrentLanguage();
  if(language != null && language === 'th'){
    culture = 'th-TH'
  }
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return  axios.create({
  
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10 * 60 * 1000,
  withCredentials: false,
  headers: {
    'Content-type': 'application/json',
    'Content-Language': culture,
     Authorization: auth && localStorageToken ? `Bearer ${localStorageToken}` : '',
     timezone : timeZone,
  },
});
}

const client = (auth:boolean = true) =>
setupInterceptorsTo(instance(auth));

export const refreshToken = (config:any) => {
	return new Promise<AxiosResponse<IAuthToken,any>>((resolve , reject) => {
  const body = JSON.stringify({
    refreshToken:  localStorage.getItem("refresh-token") ?? null
});
console.log('Call Refresh Token');
 instance().post<IAuthToken>('/v1/auth/refresh-token/', body)
			.then((res) => {
        saveUserToken(res.data.accessToken);
        saveRefreshToken(res.data.refreshToken);
        return resolve(res);
			})
			.catch((err) => {
				return reject(err);
			});
	});
};

export default client;
