export const useWebview = () => {
  //@ts-ignore
  const isWebview = window.flutter_inappwebview;

  const closeWebview = () => {
    //@ts-ignore
    if (window.flutter_inappwebview) {
      //@ts-ignore
      window.flutter_inappwebview.callHandler("closeWebview");
    }
  };

  const goToLogin = () => {
    //@ts-ignore
    if (window.flutter_inappwebview) {
      //@ts-ignore
      window.flutter_inappwebview.callHandler("goToLogin");
    }
  };

  return {
    goToLogin,
    closeWebview,
    isWebview,
  };
};
