import { useTranslation } from "react-i18next";
import SVG from "react-inlinesvg";
import exportDate from "../../assets/svg/download-data.svg";
import "./buttonExportData.scss";
import { Button } from "react-bootstrap";

export interface ButtonExportDataProps {
  icon?: string;
  title?: string;
  onClickDownloadFile?: () => void;
}

const ButtonExportData: React.FC<ButtonExportDataProps> = ({
  icon,
  title,
  onClickDownloadFile,
}) => {
  const { t } = useTranslation();

  return (
    <div className="button-export-data">
      <Button
        className="icon-content"
        onClick={onClickDownloadFile}
        variant="link"
      >
        {icon ? icon : <SVG src={exportDate} />}
        <span className="export-content">
          {title ? title : t("SCHEDULE_LOG_EXPORT_DATA")}
        </span>
      </Button>
    </div>
  );
};

export default ButtonExportData;
