import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  /// React StrictMode Bug on call useEffact in react 18
  // <React.StrictMode>
  <App />
  //</React.StrictMode>
);

serviceWorker.register();
