import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { DateTimeEnum } from "../enum/dateTimeEnum";
import 'dayjs/locale/th'
import { getCurrentLanguage } from "./languageUtil";

dayjs.extend(utc);
dayjs.extend(tz);

export const getTimeZone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const toUtc = (date: Date) => {
  return dayjs(date).tz(getTimeZone()).utc().toDate();
};

export const toLocal = (date: Date) => {
  const lang = getCurrentLanguage();
  return dayjs.utc(date).tz(getTimeZone()).locale(lang);
};

export const nextDay = () => {
  return dayjs().add(1, 'day').startOf('day').tz(getTimeZone()).utc().toDate();
}

export const groupTypeDate = (groupTypeString: string, date: Date) => {
  switch (groupTypeString) {
    case DateTimeEnum.Day:
      return toLocal(date).format("DD-MM-YYYY");
    case DateTimeEnum.Month:
      return toLocal(date).format("MMMM");
    case DateTimeEnum.Year:
      return toLocal(date).format("YYYY");
  }
  return groupTypeString;
};
