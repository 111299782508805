import React, { FC, useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import dayjs from "dayjs";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

import { DatePeriodOptionEnum } from "../../constants/datePicker";
import CustomDatePicker from "./datePicker";
import "./datePicker.scss";
import { useTranslation } from "react-i18next";

interface IDatePeriodFilterProps {
  defaultSelectedOption?: string;
  fromDate: Date;
  toDate: Date;
  setFromDate: (selectDate: Date) => void;
  setToDate: (selectDate: Date) => void;
  tooltip?: string;
  displayBuddhistYear?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const DatePeriodPicker: FC<IDatePeriodFilterProps> = ({
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  defaultSelectedOption = DatePeriodOptionEnum.Last7Days,
  tooltip,
  displayBuddhistYear = false,
  minDate,
  maxDate
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);
  const [showMainMenu, setShowMainMenu] = useState(false);
  const buddhistYear: number = 543;

  const { t } = useTranslation();

  const onSelectedOption = (value: string) => {
    const today: Date = new Date();
    let tempStartDate: Date = fromDate;
    let tempEndDate: Date = toDate;
    switch (value) {
      case DatePeriodOptionEnum.Last30Days:
        tempStartDate = dayjs(today).add(-30, "day").toDate();
        tempEndDate = today;
        break;
      case DatePeriodOptionEnum.Last7Days:
        tempStartDate = dayjs(today).add(-7, "day").toDate();
        tempEndDate = today;
        break;
      case DatePeriodOptionEnum.Today:
        tempStartDate = tempEndDate = today;
        break;
      case DatePeriodOptionEnum.Yesterday:
        tempStartDate = tempEndDate = dayjs(today).add(-1, "day").toDate();
        break;
    }

    setFromDate(
      new Date(
        tempStartDate.getFullYear(),
        tempStartDate.getMonth(),
        tempStartDate.getDate(),
        0,
        0,
        0
      )
    );
    setToDate(
      new Date(
        tempEndDate.getFullYear(),
        tempEndDate.getMonth(),
        tempEndDate.getDate(),
        23,
        59,
        59
      )
    );
  };

  const selectFromDate = (value: Date) => {
    if (dayjs(value).isAfter(toDate)) {
      setFromDate(toDate);
    } else {
      setFromDate(value);
    }
  };

  const selectToDate = (value: Date) => {
    if (dayjs(value).isBefore(fromDate)) {
      setToDate(fromDate);
    } else {
      setToDate(value);
    }
  };

  const customMainTogle = (
    isOpen: boolean,
    metadata: {
      source: string | undefined;
      originalEvent:
        | React.SyntheticEvent
        | KeyboardEvent
        | MouseEvent
        | undefined;
    }
  ) => {
    if (metadata.source === "select") setShowMainMenu(true);
    else setShowMainMenu(isOpen);
  };

  const optionDropDownItem = () => {
    return (
      <>
        {Object.values(DatePeriodOptionEnum).map((value, index) => (
          <Dropdown.Item
            eventKey={value}
            key={index}
            className="date-period-filter__select-item"
            onClick={() => onSelectedOption(value)}
          >
            <span>{displayPeriodOption(value)}</span>
          </Dropdown.Item>
        ))}
      </>
    );
  };
  const displayPeriodOption = (option: DatePeriodOptionEnum) => {
    switch (option) {
      case DatePeriodOptionEnum.Last30Days:
        return t("C_LAST30DAYS");
      case DatePeriodOptionEnum.Last7Days:
        return t("C_LAST7DAYS");
      case DatePeriodOptionEnum.Today:
        return t("C_TODAY");
      case DatePeriodOptionEnum.Yesterday:
        return t("C_YESTERDAY");
    }
    return t("C_CUSTOMRANGE");
  };
  const displayDate = () => {
    let startDate: Date = displayBuddhistYear
      ? new Date(
          fromDate.getFullYear() + buddhistYear,
          fromDate.getMonth(),
          fromDate.getDate(),
          0,
          0,
          0
        )
      : fromDate;
    let endDate: Date = displayBuddhistYear
      ? new Date(
          toDate.getFullYear() + buddhistYear,
          toDate.getMonth(),
          toDate.getDate(),
          23,
          59,
          59
        )
      : toDate;
    return (
      <span>
        {dayjs(startDate).format("DD-MM-YYYY")} &rarr;{" "}
        {dayjs(endDate).format("DD-MM-YYYY")}
      </span>
    );
  };

  useEffect(() => {
    const startDateToCheck = dayjs(fromDate).startOf('day');
    const endDateToCheck = dayjs(toDate).startOf('day');
    const today = dayjs().startOf('day');

    if (startDateToCheck.isSame(today) && endDateToCheck.isSame(today)) {
      setSelectedOption(DatePeriodOptionEnum.Today);
    } else if (startDateToCheck.isSame(today.subtract(1, "day")) && endDateToCheck.isSame(today.subtract(1, "day"))) {
      setSelectedOption(DatePeriodOptionEnum.Yesterday);
    } else if (startDateToCheck.isSame(today.subtract(7, "day")) && endDateToCheck.isSame(today)) {
      setSelectedOption(DatePeriodOptionEnum.Last7Days);
    } else if (startDateToCheck.isSame(today.subtract(30, "day")) && endDateToCheck.isSame(today)) {
      setSelectedOption(DatePeriodOptionEnum.Last30Days);
    } else {
      setSelectedOption(DatePeriodOptionEnum.CustomRange);
    }
  }, [fromDate, toDate]);

  return (
    <Dropdown
      className="date-period-filter"
      show={showMainMenu}
      onToggle={customMainTogle}
    >
      <OverlayTrigger
        placement="top"
        delay={{ show: 0, hide: 100 }}
        overlay={
          tooltip ? (
            <Tooltip id="tooltip-date-filter">{tooltip}</Tooltip>
          ) : (
            <span></span>
          )
        }
      >
        <Dropdown.Toggle
          className="main-btn"
          variant=""
          id="dropdown-date-period"
        >
          {displayDate()}
          <CalendarMonthOutlinedIcon className="main-icon" />
        </Dropdown.Toggle>
      </OverlayTrigger>
      <Dropdown.Menu className="period-menu">
        <div className="menu-box">
          <div className="select row">
            <Dropdown className="select-ddl col-12 col-sm-6">
              <Dropdown.Toggle
                className="select-btn"
                variant=""
                id="dropdown-option"
              >
                {displayPeriodOption(selectedOption as DatePeriodOptionEnum)}
              </Dropdown.Toggle>
              <Dropdown.Menu className="select-menu">
                {optionDropDownItem()}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="date row">
            <div className="col-12 col-sm-6">
              <div className="label">{t("FROM")}</div>
              <CustomDatePicker
                setDate={selectFromDate}
                selectDate={fromDate}
                showTimeSelect={false}
                displayBuddhistYear={displayBuddhistYear}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
            <div className="col-12 col-sm-6">
              <div className="label">{t("TO")}</div>
              <CustomDatePicker
                setDate={selectToDate}
                selectDate={toDate}
                showTimeSelect={false}
                displayBuddhistYear={displayBuddhistYear}
                minDate={minDate}
                maxDate={maxDate}
              />
            </div>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DatePeriodPicker;
