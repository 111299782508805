import "./backgroundBubble.scss";

const BackgroundBubble: React.FC = () => {
    return (
        <div className="bubbles">
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
            <div className="bubble" />
        </div>
    );
};

export default BackgroundBubble; 