import { useEffect, useState } from "react";
import client from "../utils/axios";
import {
  ConfirmDeleteUserFormInterface,
  DeleteUserResponseInterface,
  UserDeleteRequestInterface,
} from "../Interfaces/userDelete";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import isUndefined from "lodash/isUndefined";

export default function useUserDelete() {
  const [userDeleteRequest, setUserDeleteRequest] =
    useState<UserDeleteRequestInterface>();

  useEffect(() => {
    client()
      .get<UserDeleteRequestInterface>("/v1/users/delete/request")
      .then((res: AxiosResponse<UserDeleteRequestInterface>) => {
        if (!isUndefined(res.data)) {
          setUserDeleteRequest(res.data);
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  const onDeleteUser = (formValue: ConfirmDeleteUserFormInterface) => {
    return client().delete<DeleteUserResponseInterface>("/v1/users/delete", {
      data: JSON.stringify(formValue),
    });
  };

  return {
    userDeleteRequest,
    onDeleteUser,
  };
}
