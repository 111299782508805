import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ChartOptions } from "chart.js";
import CardLayout from "../cardLayout/cardLayout";
import "../lineChart/lineChart.scss";
import { Spinner } from "react-bootstrap";
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(annotationPlugin);

export interface LineChartProps {
  data: any;
  options?: ChartOptions<"line">;
  isLoading?: boolean;
  displayNoData?: boolean;
  noDataText?: string;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  options,
  isLoading = false,
  displayNoData = false,
  noDataText,
}) => {
  return (
    <CardLayout padding={0}>
      {displayNoData ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <span className="text-detail">{noDataText}</span>
        </div>
      ) : (
        <div className="card-linechart">
          {isLoading ? (
            <div className="loader-wrapper">
              <Spinner
                animation="border"
                className="chart-spinner"
                variant="secondary"
              />
            </div>
          ) : (
            <div className="linechart-container">
              <Line data={data} options={options} />
            </div>
          )}
        </div>
      )}
    </CardLayout>
  );
};

export default LineChart;
