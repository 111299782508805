import { Nav } from "react-bootstrap";

import { ISidebarItem, SIDEBAR_ITEMLIST } from "../../constants/sidebarItem";

import "./sidebar.scss";

import ItemLink from "./item/item";
import BottomSidebarItem from "./bottomSidebarItem";
import { getUserToken } from "../../utils/authUtil";
import { FactoryRoleEnum } from "../../enum/factoryRole";
export interface SidebarProps {
  appVersion: string;
  isSidebarCollapsed: boolean;
}

const Sidebar: React.FC<SidebarProps> = (props: SidebarProps) => {
  let sidebarItems: ISidebarItem[] = SIDEBAR_ITEMLIST;
  const user = getUserToken();

  if (Number(user?.selected_factory) === 0) {
    sidebarItems = sidebarItems.filter((item) => item.text !== "MENU_MACHINE");
  }

  if (Number(user?.selected_factory_role) === FactoryRoleEnum.MEMBER) {
    sidebarItems = sidebarItems.filter((item) => item.text === "MENU_REPORT");
  }

  return (
    <div className={"sidebar" + (props.isSidebarCollapsed ? " collapsed" : "")}>
      <div className="center">
        <Nav activeKey="/home">
          {sidebarItems.map((item, index) => {
            return <ItemLink key={index} {...item} />;
          })}
        </Nav>
      </div>
      <BottomSidebarItem />
    </div>
  );
};

export default Sidebar;
