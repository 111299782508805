import "react-datepicker/dist/react-datepicker.css";
import { Outlet } from "react-router-dom";

const PlainLayout = () => {
  return (
    <div className="app">
      <Outlet />
    </div>
  );
};
export default PlainLayout;
