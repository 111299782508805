import i18n from '../localization/config';

const t = i18n.t.bind(i18n);

export interface MachineEfficiencyExportParams {
  machineId: number;
  from: Date;
  to: Date;
}

export const MACHINE_EFFIECIENCY_CSV_HEADER = [
  { label: t("REPORT_DATE"), key: "date" },
  { label: t("REPORT_MACHINE_NAME"), key: "machineName" },
  { label: t("REPORT_MACHINE_TYPE_NAME"), key: "machineTypeName" },
  { label: t("REPORT_STATUS"), key: "status" },
  { label: t("REPORT_EFFIIENCY_VALUE"), key: "efficiencyValue" },
  { label: t("REPORT_EFICIENCY_CHILLER"), key: "efficiencyChiller" },
];

export const MACHINE_MAINTENANCE_CSV_HEADER = [
  { label: t("REPORT_SCHEDULE_DATE_MAINTENANCE"), key: "scheduleDate" },
  { label: t("REPORT_MACHINE_NAME"), key: "mechineName" },
  { label: t("REPORT_SCHEDULE_NAME"), key: "scheduleName" },
  { label: t("REPORT_DATE_MAINTENANCE"), key: "date" },
  { label: t("REPORT_RESULT_MAINTENANCE"), key: "resultPassed" },
  { label: t("REPORT_STATUS"), key: "maintenanceStatus" },
  { label: t("REPORT_RECORDER_NAME_MAINTENANCE"), key: "recorderName" },
  { label: t("REPORT_RECORDER_NOTE"), key: "note" },
];

export const MACHINE_SCHEDULE_CSV_HEADER = [
  { label: t("REPORT_SCHEDULE_DATE"), key: "scheduleDate" },
  { label: t("REPORT_MACHINE_NAME"), key: "mechineName" },
  { label: t("REPORT_SCHEDULE_NAME"), key: "scheduleName" },
  { label: t("REPORT_DATE"), key: "date" },
  { label: t("REPORT_GAUGE_NAME"), key: "gaugeName" },
  { label: t("REPORT_GAUGE_TYPE_NAME"), key: "gaugeTypeName" },
  { label: t("REPORT_VALUE_GAUGE"), key: "valueGauge" },
  { label: t("REPORT_UNIT"), key: "unit" },
  { label: t("REPORT_RECORDER_TYPE_NAME"), key: "recordTypeName" },
  { label: t("REPORT_STATUS"), key: "status" },
  { label: t("REPORT_RECORDER_NAME"), key: "recorderName" },
  { label: t("REPORT_RECORDER_NOTE"), key: "recordNote" },
  { label: "latitude", key: "latitude" },
  { label: "longitude", key: "longitude" },
];
