  export enum Role {
    OWNER = 1,
    MEMBER = 2,
  }
  
  export enum FactoryRole {
    SUPER_ADMIN = "Factory superadmin",
    ADMIN = "Factory admin",
    MEMBER = "Factory Member",
    NONE = "",
  }
  