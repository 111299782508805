import _ from "lodash";
import { COLOR_CHART } from "../constants/chartConstants";
import { DateTimeEnum, ThresholdDateEnum } from "../enum/dateTimeEnum";
import { toLocal } from "./dateTimeUtil";
import { ChartLabelBuilder, ChartValueBuilder, DashboardEfficiencyInterface } from "../Interfaces/dashboard";
import { getCurrentLanguage } from "./languageUtil";

export function getDashboardValue(startDate: Date, endDate: Date, data: DashboardEfficiencyInterface[]) {
  let chartData: any[] = [];

  const { labels, baseDataKey } = getLabelAndArrayKey(startDate, endDate, data);

  if (!_.isNil(data)) {
    chartData = data?.map((e: any, index: number) => {
      return {
        yAxisID: `y`,
        label: e.machineName,
        data: getChartValues(e.data, baseDataKey),
        borderColor: COLOR_CHART[index],
        backgroundColor: COLOR_CHART[index],
      };
    });
  }

  return [labels, chartData];
}

const getLabelAndArrayKey = (startDate: Date, endDate: Date, data: ChartLabelBuilder[]) => {
  const lang = getCurrentLanguage();
  
  const { groupType, diff } = checkGroupTypeAndDiffRange(startDate, endDate, data);
  const baseDataKey: { [key: number]: number; } = {};

  const labels = Array.from({ length: diff }, (_, index) => {
    const currentDate = new Date(startDate);
    currentDate.setHours(0, 0, 0, 0);

    if (groupType === DateTimeEnum.Year) {
      currentDate.setFullYear(currentDate.getFullYear() + index);
      currentDate.setDate(1);
      currentDate.setMonth(0);

      baseDataKey[currentDate.valueOf()] = NaN;

      return currentDate.getFullYear().toString();
    }

    if (groupType === DateTimeEnum.Month) {
      currentDate.setMonth(currentDate.getMonth() + index);
      currentDate.setDate(1);

      baseDataKey[currentDate.valueOf()] = NaN;

      const formatter = new Intl.DateTimeFormat(lang, { month: 'long' });
      return formatter.format(currentDate);
    }

    currentDate.setDate(currentDate.getDate() + index);
    baseDataKey[currentDate.valueOf()] = NaN;

    const mm = currentDate.getMonth() + 1; // getMonth() is zero-based
    const dd = currentDate.getDate();

    return [
      (dd > 9 ? '' : '0') + dd,
      (mm > 9 ? '' : '0') + mm,
      currentDate.getFullYear(),
    ].join('-');
  });

  return { labels, baseDataKey };
};

const checkGroupTypeAndDiffRange = (startDate: Date, endDate: Date, data: ChartLabelBuilder[]) => {
  const groupType = data && data.length > 0 ? data[0].groupTypeString : defaultGroupTypeString(startDate,endDate);
  let diff;

  if (groupType === DateTimeEnum.Month) {
    diff = diffInMonths(startDate, endDate);
  } else if (groupType === DateTimeEnum.Year) {
    diff = diffInYears(startDate, endDate);
  } else {
    diff = diffInDays(startDate, endDate);
  }

  return { groupType, diff };
};

const defaultGroupTypeString = (startDate: Date, endDate: Date) => {
  const dateDif = diffInDays(startDate, endDate);
  if(dateDif > ThresholdDateEnum.Year){
    return DateTimeEnum.Year
  }
  if(dateDif > ThresholdDateEnum.Month){
    return DateTimeEnum.Month
  }
  return DateTimeEnum.Day
}

export const getChartValues = (data: ChartValueBuilder[], baseKey: { [key: number]: number; }) => {
  let setDataToKey = _.cloneDeep(baseKey);

  if (_.isUndefined(data)) {
    return [];
  }

  data.forEach(e => {
    const timestamp = toLocal(e.date);
    const dayTimestamp = timestamp.startOf('day').valueOf();
    if (setDataToKey[dayTimestamp] !== undefined) {
      setDataToKey[dayTimestamp] = e.value;
    }
  });

  return [...Object.values(setDataToKey)];
};

export function getChartLabels(
  startDate: Date,
  endDate: Date,
  data: ChartLabelBuilder[],
) {
  const { labels, baseDataKey } = getLabelAndArrayKey(startDate, endDate, data);

  return { labels, baseDataKey };
}

// Check diff utils
const diffInDays = (startDate: Date, endDate: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY) + 1;
};

const diffInMonths = (startDate: Date, endDate: Date) => {
  const month = 12;
  return (endDate.getFullYear() - startDate.getFullYear()) * month + (endDate.getMonth() - startDate.getMonth()) + 1;
};

const diffInYears = (startDate: Date, endDate: Date) => {
  return endDate.getFullYear() - startDate.getFullYear() + 1;
};
