
import jwt_decode from "jwt-decode";
import { UserToken } from "../Interfaces/userToken";
import { AuthProviderId } from "../enum/authProviderId";

export const getUserToken = () => {
  const token = localStorage.getItem("token") ?? null;
  if (token) {
    const user: UserToken = jwt_decode(token) as UserToken;
    return user;
  }
  return null;
};

export const saveUserToken = (token: string) => {
    localStorage.setItem("token", token);
  };

export const removeToken = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh-token");
};

export const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refresh-token") ?? null;
  return refreshToken;
};

export const saveRefreshToken = (refreshToken: string) => {
  localStorage.setItem("refresh-token", refreshToken);
};

export const saveLoginProviderId = (providerId: string) => {
  localStorage.setItem("login-provider-id", providerId);
};

export const isPasswordLogin = () => {
  const loginProvider = localStorage.getItem("login-provider-id") as AuthProviderId;
  return loginProvider === AuthProviderId.PASSWORD;
};

export const isJsonString = (stringValue: string) => {
  try {
    JSON.parse(stringValue);
  } catch (e) {
    return false;
  }
  return true;
};
