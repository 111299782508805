import { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { ISidebarItem } from "../../../constants/sidebarItem";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SvgIcon from "@mui/material/SvgIcon";
// import SvgIcon, { SvgIconTypeMap } from "@mui/material/SvgIcon";
// import { OverridableComponent } from "@mui/material/OverridableComponent";
// import { IconType, GenIcon } from "react-icons";
// import React from "react";

const ItemLink: FC<ISidebarItem> = ({ path, text, group, icon }): JSX.Element => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const itemKey = path + text;
  const { t } = useTranslation();

  const isSelected = pathname === path; // TODO => Fix this

  const renderGroupArrowIcon = function (group: ISidebarItem[], show: boolean) {
    if (group.length > 0) {
      return show ? <KeyboardArrowUpIcon className={'side__group_arrow'} /> : <KeyboardArrowDownIcon className={'side__group_arrow'} />;
    }
  }

  const renderIcon = function (icon?: any): JSX.Element {
    if (icon) {
      if (icon.type !== undefined && icon.type.render !== undefined && icon.type.render.muiName !== undefined) {
        // Material UI icons
        return <SvgIcon component={icon} />;
      }
      else if (typeof icon == "string") {
        // Custom icons with source passed as string.
        return <SVG src={icon} />;
      }
      else {
        if (typeof icon === 'function') {
          // React-icons >> https://react-icons.github.io/react-icons <<
          return icon();
        }

        if (typeof icon === 'object') {
          return <SvgIcon component={icon} />;
        }

        // Already a JSX Element
        return icon;
      }
    }

    // Not specified - no icon -
    return <div />;
  };

  useEffect(() => {
    if (isSelected) {
      document.title = t(text);
    }
  }, [isSelected, t, text]);

  const linkAction = (path: string | undefined) => {
    // TODO : check permission before push
    if (path) {
      document.title = t(text);
      navigate(path as string);
    }
  };

  return (
    <>
      <Nav.Item
        key={itemKey}
        className={isSelected ? "selected" : ""}
        onClick={() => setShow(!show)}
      >
        <Nav.Link eventKey={itemKey} className={"sidebar-content"} onClick={() => linkAction(path)}>
          {renderIcon(icon)}
          <span>{t(text)}</span>
        </Nav.Link>
        {renderGroupArrowIcon(group, show)}
      </Nav.Item>
      {show &&
        group?.length > 0 &&
        group.map((item) => {
          const selecedChild = item.path === pathname;
          return (
            <Nav.Item key={itemKey + ":" + item.path} className={selecedChild ? "selected" : ""}>
              <Nav.Link
                eventKey={itemKey}
                className={"sidebar-content side__group_item"}
                onClick={() => linkAction(item.path)}
              >
                {renderIcon(item.icon)}
                <span>{t(item.text)}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
    </>
  );
};

export default ItemLink;
