import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Bell } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import {
  NotificationInterface,
  NotificationListInterface,
} from "../../Interfaces/notification";
import "../notificationDropdown/notificationDropdown.scss";
import client from "../../utils/axios";
import { toast } from "react-toastify";
import { toLocal } from "../../utils/dateTimeUtil";
import classNames from "classnames";
import _ from "lodash";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DELAY_HIDE, DELAY_SHOW } from "../../constants/tooltip";
import "react-tooltip/dist/react-tooltip.css";

const NotificationDropdown: React.FC = () => {
  const { t } = useTranslation();
  const [limit, setLimit] = useState<number>(5);
  const [notificationList, setNotificationList] =
    useState<NotificationListInterface>();

  useEffect(() => {
    getNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const getNotification = () => {
    client()
      .get<NotificationListInterface>(`/v1/notification/${limit}`)
      .then((res) => {
        setNotificationList(res.data);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const getreadNotification = (readId: number) => {
    client()
      .patch<NotificationInterface>(`/v1/notification/${readId}`)
      .then((res) => {
        getNotification();
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          {props.children}
        </a>
      );
    }
  );

  const handleView = () => {
    const find = notificationList?.data.filter((item) => item.id);

    if (!_.isUndefined(find) && find?.length >= limit) {
      return (
        <span className="button-view" onClick={handleSummitView}>
          {t("VIEW_MORE")}
        </span>
      );
    } else {
      return <></>;
    }
  };

  const handleSummitView = () => {
    setLimit((prevState: number) => prevState + 5);
  };

  const changeNotification = (id: number): void => {
    const readId = id;
    getreadNotification(readId);
  };

  const checkNotification = () => {
    const find = notificationList?.data.filter((item) => item.read === false);

    if (!_.isUndefined(find) && find?.length > 0) {
      return <span className="notification-content"></span>;
    } else {
      return <></>;
    }
  };

  return (
    <div
      className="dropdown-notification"
      id="tooltip-notification"
      data-tooltip-content={t("NOTIFICATION")}
    >
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <div className="round-icon me-2">
            <>
              <Bell className="" />
              {checkNotification()}
            </>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="menu-dropdown">
          <div>
            <span className="title-menu">{t("NOTIFICATION")}</span>
            <>
              {notificationList?.data.map((item) => {
                return (
                  <Dropdown.Item
                    key={item.id}
                    value={item.id}
                    className={classNames(
                      `${item.read ? "text-noti-read" : "text-noti-new"}`
                    )}
                    onClick={() => changeNotification(item.id)}
                  >
                    <div className="text-title">{item.title} </div>
                    <div className="text-message">{item.detail}</div>
                    <div className="text-date">
                      {toLocal(item.createOn).format("DD MMMM YYYY  HH:mm")}
                    </div>
                  </Dropdown.Item>
                );
              })}
            </>
            <>{handleView()}</>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ReactTooltip
        anchorId="tooltip-notification"
        delayShow={DELAY_SHOW}
        delayHide={DELAY_HIDE}
      />
    </div>
  );
};

export default NotificationDropdown;
