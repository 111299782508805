import { Container } from "react-bootstrap";
import BackgroundBubble from "../../components/background-bubble/backgroundBubble";
import ConfirmDeleleteUserCard from "../../components/confirmDeleteUserCard/confirmDeleteUserCard";
import DeleteSuccessCard from "../../components/confirmDeleteUserCard/deleteSuccessCard/deleteSuccessCard";
import { useState } from "react";
import useUserDelete from "../../hooks/useUserDelete";
import isUndefined from "lodash/isUndefined";

import "./confirmDeleteUserPage.scss";

const ConfirmDeleteUserPage = () => {
  const { userDeleteRequest } = useUserDelete();
  const [isDeleteSuccess, setIsDeleteSuccess] = useState<boolean>(false);

  const onDeleteResponse = (isDeleteSuccess: boolean) => {
    setIsDeleteSuccess(isDeleteSuccess);
  };

  const confirmDeleteUserCard: JSX.Element =
    !isDeleteSuccess && !isUndefined(userDeleteRequest) ? (
      <ConfirmDeleleteUserCard
        userDeleteRequest={userDeleteRequest}
        onDeleteResponse={onDeleteResponse}
      />
    ) : (
      <></>
    );

  return (
    <Container fluid className="background-bubbles confirm-delete-user">
      <BackgroundBubble />

      {confirmDeleteUserCard}

      {isDeleteSuccess ? <DeleteSuccessCard /> : null}
    </Container>
  );
};

export default ConfirmDeleteUserPage;
