import { createContext, FC, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, matchPath } from "react-router-dom";
import { PATH, PUBLIC_PATH } from "../constants/path";
import { Profile } from "../Interfaces/profile";
import client from "../utils/axios";
import { LoaderContext } from "./loaderContext";
import _ from "lodash";
interface IProfileContext {
  profile?: Profile;
  loadProfile?: (disableLoadingScreen?: boolean) => void;
  loading?: boolean;
}

export const ProfileContext = createContext<IProfileContext>({});

export interface ProfileProviderProps {
  children: any;
}

export const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState<Profile>();
  const { showLoading, closeLoading } = useContext(LoaderContext);

  const isPublicPage = () => {
    for (let i = 0; i < PUBLIC_PATH.length; i++) {
      let match: boolean = !!matchPath(
        {
          path: PUBLIC_PATH[i],
        },
        pathname
      );
      if (match) return match;
    }
    return false;
  };

  const loadProfile = (disableLoadingScreen : boolean = false) => {
    if (isPublicPage()) {
      return;
    }
    // /**
    //  * Authroize with /profile
    //  */

    if (!disableLoadingScreen) {
      setLoading(true);
      showLoading();
    }

    client()
      .get<Profile>("/v1/profile/")
      .then((response) => {
        if (
          _.isNil(response.data.organization) &&
          pathname !== PATH.NEW_ORGANIZATION
        ) {
          navigate(PATH.NEW_ORGANIZATION);
        } else {
          setProfile(response.data);
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          //TODO: Reflesh token flow
          navigate(PATH.LOGIN);
        }
      })
      .finally(() => {
        setLoading(false);
        closeLoading();
      });
  };

  useEffect(() => {
    loadProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileContext.Provider
      value={{
        profile,
        loadProfile,
        loading,
      }}
    >
      {/* TODO: Loading page */}
      {loading && <></>}
      {!loading && children}
    </ProfileContext.Provider>
  );
};
