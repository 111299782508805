import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GlobalContext } from "../context/globalContext";
import { EfficiencyInterface } from "../Interfaces/schedule";
import client from "../utils/axios";

const useMachineEfficiency = () => {
  const {
    machines,
    isMachineLoading,
    startDate,
    endDate,
    setStartDateState,
    setEndDateState,
    selectedMachine,
    setSelectedMachineState,
    minDate,
    maxDate
  } = useContext(GlobalContext);

  const [machineId, setMachineId] = useState<number>(0);
  const [machineLabel, setMachineLabel] = useState<string>("");
  const [efficiencyReport, setEfficiencyReport] = useState<
    EfficiencyInterface[]
  >([]);
  const [isReportLoad, setIsReportLoad] = useState<boolean>(false);

  useEffect(() => {
    if (machines.length > 0) {
      setMachineId(selectedMachine?.id ?? 0);
      setMachineLabel(selectedMachine?.name ?? "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machines]);

  useEffect(() => {
    if (machineId > 0) {
      getEfficiencyReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [machineId, startDate, endDate]);

  const getEfficiencyReport = () => {
    setIsReportLoad(true);

    client()
      .get<EfficiencyInterface[]>(`/v1/report/efficiency-report`, {
        params: {
          machineId: machineId,
          from: startDate,
          to: endDate,
        },
      })
      .then((res) => {
        setEfficiencyReport(res.data);
      })
      .catch((err) => {
        toast.error(err.toString());
      })
      .finally(() => {
        setIsReportLoad(false);
      });
  };

  return {
    machines,
    startDate,
    endDate,
    setStartDateState,
    setEndDateState,
    machineLabel,
    efficiencyReport,
    setMachineLabel,
    setMachineId,
    machineId,
    isReportLoad,
    isMachineLoading,
    selectedMachine,
    setSelectedMachineState,
    minDate,
    maxDate
  };
};

export default useMachineEfficiency;
