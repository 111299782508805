import { FC, useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import { getUserToken } from "../../utils/authUtil";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../constants/path";
import {
  ConfirmDeleteUserFormInterface,
  DeleteUserResponseInterface,
  UserDeleteRequestInterface,
} from "../../Interfaces/userDelete";
import isEmpty from "lodash/isEmpty";
import { ModalDialog } from "../../loadable";
import * as yup from "yup";
import { FactoryUserInterface } from "../../Interfaces/factory";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import isNil from "lodash/isNil";

import "./confirmDeleteUserCard.scss";
import useUserDelete from "../../hooks/useUserDelete";
import { AxiosResponse } from "axios";
import CustomButton from "../customButton/customButton";
import { toast } from "react-toastify";
import { useWebview } from "../../hooks/useWebview";

interface ConfirmDeleteUserCardInterface {
  userDeleteRequest: UserDeleteRequestInterface;
  onDeleteResponse: (response: any) => void;
}

const ConfirmDeleleteUserCard: FC<ConfirmDeleteUserCardInterface> = ({
  userDeleteRequest: { isCanDelete, users },
  onDeleteResponse,
}) => {
  const user = getUserToken();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { onDeleteUser } = useUserDelete();
  const { closeWebview, isWebview } = useWebview();
  const isShouldTransfer = !isCanDelete && !isEmpty(users);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowDeleteModal, setisShowDeleteModal] = useState<boolean>(false);

  const schema = yup.object().shape({
    validateName: yup.string().required(t("REGISTER_NAME_REQUIRED")),
  });

  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
    setValue,
    setError,
  } = useForm<ConfirmDeleteUserFormInterface>({
    resolver: yupResolver(schema),
  });

  const watchValidateName = watch("validateName");

  useEffect(() => {
    if (isShouldTransfer) {
      setValue("newOwnerId", users[0].id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const onConfirm = async () => {
    if (user?.name !== watchValidateName) {
      setError("validateName", {
        type: "custom",
        message: t("CONFIRM_DELETE_PLEASE_FILL_CORRECT_NAME"),
      });
      return;
    }

    setisShowDeleteModal(true);
  };

  const onConfirmDeleteUser = async () => {
    setIsLoading(true);

    const { newOwnerId, validateName } = await getValues();
    await onDeleteUser({ newOwnerId, validateName })
      .then((res: AxiosResponse<DeleteUserResponseInterface>) => {
        if (res.data.isSuccess) {
          onDeleteResponse(true);
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCloseDeleteModal = () => {
    if (isLoading) {
      return;
    }

    setisShowDeleteModal(false);
  };

  return (
    <div className="delete-user-card">
      <Card>
        <Card.Body>
          <Card.Title className="delete-user-card__title">
            {t("SETTING_PAGE_DELETE_BUTTON_TITLE")}
          </Card.Title>

          <div className="card-content">
            {isShouldTransfer && (
              <div className="title">
                {t("CONFIRM_DELETE_USER_PAGE_DESCRIPTION")}
              </div>
            )}

            <Form onSubmit={handleSubmit(onConfirm)}>
              <div className="form-wrapper">
                {isShouldTransfer && (
                  <Form.Group className="form-group">
                    <Form.Label>
                      {t("CONFIRM_DELETE_USER_PAGE_TRANSFER_TO")}
                    </Form.Label>
                    <div>
                      <Select
                        className="custom-dropdown text-body3 "
                        classNamePrefix="custom-dropdown"
                        options={users}
                        defaultValue={users[0]}
                        getOptionLabel={(option: FactoryUserInterface) =>
                          option.name
                        }
                        getOptionValue={(option: FactoryUserInterface) =>
                          option.id.toString()
                        }
                        onChange={(
                          selected: SingleValue<FactoryUserInterface>
                        ) => {
                          if (!isNil(selected))
                            setValue("newOwnerId", selected.id);
                        }}
                        components={{
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </Form.Group>
                )}

                <Form.Group className="form-group">
                  <Form.Label>
                    {t("CONFIRM_DELETE_USER_PAGE_REPEAT_NAME_AGAIN")}
                  </Form.Label>
                  <Form.Control
                    {...register("validateName")}
                    type="text"
                    placeholder={user?.name}
                    isInvalid={!!errors.validateName}
                  />
                  {!!errors.validateName && (
                    <Form.Control.Feedback type="invalid">
                      {errors.validateName.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <div className="button-section">
                  <Button
                    variant="outline-primary"
                    onClick={() => {
                      if (isWebview) {
                        closeWebview();
                      } else {
                        navigate(PATH.SETTING);
                      }
                    }}
                  >
                    {t("CANCEL")}
                  </Button>

                  <Button
                    type="submit"
                    className="button-section--delete"
                    variant="danger"
                  >
                    {t("SETTING_PAGE_DELETE_BUTTON_TITLE")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Card.Body>
      </Card>

      <ModalDialog show={isShowDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("ORGANIZATION_MEMBER_DELETE_TITLE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("CONFIRM_DELETE_USER_DELETE_MODAL_DETAIL")}</Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={handleCloseDeleteModal}>
            {t("CANCEL")}
          </Button>
          <CustomButton
            type="submit"
            className="button-section--delete"
            variant="danger"
            isLoading={isLoading}
            onClick={() => onConfirmDeleteUser()}
          >
            {t("SETTING_PAGE_DELETE_BUTTON_TITLE")}
          </CustomButton>
        </Modal.Footer>
      </ModalDialog>
    </div>
  );
};

export default ConfirmDeleleteUserCard;
