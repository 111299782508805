import { Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SVG from "react-inlinesvg";
import { PATH } from "../../../constants/path";
import CheckCircleFill from "../../../assets/svg/check-circle-fill.svg";
import "../confirmDeleteUserCard.scss";
import { useWebview } from "../../../hooks/useWebview";

const DeleteSuccessCard = () => {
  const { isWebview, goToLogin } = useWebview();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="success-delete-card">
      <Card>
        <Card.Body>
          <div className="success-card">
            <SVG className="check-circle-fill" src={CheckCircleFill} />

            <div className="success-card--title">
              {t("CONFIRM_DELETE_USER_SUCCESS")}
            </div>

            <Button
              variant="primary"
              onClick={() => {
                if (isWebview) {
                  goToLogin();
                } else {
                  navigate(PATH.LOGIN);
                }
              }}
            >
              {t("RESET_PASSOWRD_TO_LOGIN")}
            </Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default DeleteSuccessCard;
