import { ReactNode } from "react";
import { Button, Spinner } from "react-bootstrap";

interface ICustomButtonProps {
    isLoading?: boolean,
    disabled?: boolean,
    variant?: string,
    className?: string,
    onClick?: () => void,
    children?: ReactNode,
    id?: string,
    size?: 'sm' | 'lg',
    type?: 'submit' | 'reset' | 'button' | undefined,
}

const CustomButton: React.FC<ICustomButtonProps> = (props: ICustomButtonProps) => {
    const handleClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }

    return <Button id={props.id} className={"text-white " + props.className} size={props.size} variant={props.variant} disabled={props.disabled || props.isLoading} onClick={handleClick} type={props.type}>
        {props.isLoading && (
            <Spinner animation="border" role="status" size="sm" variant="light" className="me-2">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        )}

        {!props.isLoading && props.children}
    </Button>
}

export default CustomButton;