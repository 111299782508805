import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

i18n
.use(initReactI18next)
.use(LanguageDetector)
.init({
  fallbackLng: 'en',
  debug:true,
  resources: {
    en: {
      translations: require('./resources/en.json'),
    },
    th: {
      translations: require('./resources/th.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  detection: {
    caches: ['localStorage'],
    lookupQuerystring: 'lang',
    lookupLocalStorage: 'abc_i18next',
    lookupFromPathIndex: 0,
    order: ['querystring', 'localStorage'],
    interpolation: {
      escapeValue: false
    },
  },
});

i18n.languages = ['th', 'en'];

export default i18n;
