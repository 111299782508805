import React, { FC, useEffect } from "react";
import { Dropdown, Image } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useTranslation } from "react-i18next";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { DELAY_HIDE, DELAY_SHOW } from "../../constants/tooltip";
import "react-tooltip/dist/react-tooltip.css";
import burgerMenu from "../../assets/svg/hamburger_menu.svg";
import appLogo from "../../assets/svg/nav/abc-logo.svg";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import {
  BsPerson,
  BsBoxArrowRight,
  BsWrenchAdjustableCircle,
  BsGear,
} from "react-icons/bs";
import { TfiBook } from "react-icons/tfi";
import { Building } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

import "./navbar.scss";
import { PATH } from "../../constants/path";
import NotificationDropdown from "../notificationDropdown/notificationDropdown";
import client from "../../utils/axios";
import useProfile from "../../hooks/useProfile";
import { FactoryRoleEnum } from "../../enum/factoryRole";
import { ProfileFactoryRole } from "../../Interfaces/profile";
import { getUserToken } from "../../utils/authUtil";

export interface NavbarProps {
  toggleSidebar: () => void;
}

const Navbar: FC<NavbarProps> = (props: NavbarProps) => {
  const UserMachineUrl: string = process.env.REACT_APP_MACHINE_MANUAL_URL ?? "";

  type CustomToggleProps = {
    children?: React.ReactNode;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {};
  };

  const CustomToggle = React.forwardRef(
    (props: CustomToggleProps, ref: React.Ref<HTMLAnchorElement>) => {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href=""
          ref={ref}
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        >
          {props.children}
        </a>
      );
    }
  );
  const user = getUserToken();
  const { profile } = useProfile();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const handleLogout = async (): Promise<void> => {
    await localStorage.removeItem("token");
    await localStorage.removeItem("refresh-token");
    window.location.href = PATH.LOGIN;
  };

  const getProfile = () => {
    client().get("v1/profile");
  };
  const getUserManual = async () => {
    await client()
      .get("v1/settings/user-manual")
      .then((res) => {
        if (res.data.fileUrl) {
          const link = document.createElement("a");
          link.href = res.data.fileUrl;
          link.setAttribute("download", res.data.fileName);
          link.setAttribute("target", "_blank");

          document.body.appendChild(link);

          link.click();
          if (link.parentNode) {
            link.parentNode.removeChild(link);
          }
        }
      });
  };
  const checkProfileAvatar = () => {
    if (profile?.avatar) {
      return (
        <label className="profile-avatar">
          <div className="profile-preview-avatar">
            <Image src={profile?.avatar} alt="" width={50} height={50} />
          </div>
        </label>
      );
    }
    return <AccountCircleRoundedIcon className="round-icon" />;
  };

  const checkRoleFactory = () => {
    const findRole = profile?.userFactory?.find(
      (e) => e.roleId === FactoryRoleEnum.SUPER_ADMIN
    );
    if (findRole) {
      return (
        <div
          className="round-icon me-2"
          onClick={() => navigate(PATH.ORGANIZATION)}
          id="tooltip-organization"
          data-tooltip-content={t("MANAGE_ORGANIZATION")}
        >
          <Building className="" />
        </div>
      );
    }
    return <></>;
  };

  const getFactoryName = () => {
    if (
      profile &&
      profile.userFactory &&
      user != null &&
      user.selected_factory
    ) {
      const factoryRole = profile.userFactory.find(
        (value: ProfileFactoryRole) =>
          (value.factory?.id ?? 0) === Number(user.selected_factory)
      );
      if (factoryRole) {
        return factoryRole.factory?.name;
      }
    }
    return "";
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="container__left">
          <div className="hamburger-menu" onClick={() => props.toggleSidebar()}>
            <SVG src={burgerMenu} />
          </div>
          <div className="logo-container">
            <Image className="logo" src={appLogo} />
            <div className="name">ABC</div>
          </div>
        </div>
        <div className="factory-name">{getFactoryName()}</div>
        <div className="container__right pe-2">
          {/* <DropdownButton
                        id="languages-ddl"
                        variant="secondary"
                        title={props.getSelectedLanguage()}
                        onSelect={(e) => props.onChangedLanguage(e ? e : 'th')}
                    >
                        <Dropdown.Item eventKey="en">{languagesEnum.ENGLISH}</Dropdown.Item>
                        <Dropdown.Item eventKey="th">{languagesEnum.THAI}</Dropdown.Item>
                    </DropdownButton> */}

          {checkRoleFactory()}
          <NotificationDropdown></NotificationDropdown>

          <Dropdown className="dropdown-user-profile">
            <Dropdown.Toggle as={CustomToggle} id="user-profile-toggle">
              {checkProfileAvatar()}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/user-profile">
                <BsPerson />
                <span className="ms-2">{t("USER_PROFILE_PAGE_NAME")}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => getUserManual()}>
                <TfiBook />
                <span className="ms-2">{t("USER_SYSTEM_MANUAL")}</span>
              </Dropdown.Item>
              <Dropdown.Item
                href={UserMachineUrl}
                target="_blank"
              >
                <BsWrenchAdjustableCircle />
                <span className="ms-2">{t("USER_MACHINE_MANUAL")}</span>
              </Dropdown.Item>
              <Dropdown.Item href="/setting">
                <BsGear />
                <span className="ms-2">{t("MENU_SETTING")}</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleLogout()}>
                <BsBoxArrowRight />
                <span className="ms-2">{t("LOGOUT")}</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ReactTooltip
          anchorId="tooltip-organization"
          delayShow={DELAY_SHOW}
          delayHide={DELAY_HIDE}
        />
      </div>
    </div>
  );
};

export default Navbar;
