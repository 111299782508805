export enum DatePeriodOptionEnum {
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last 7 Days",
  Last30Days = "Last 30 Days",
  CustomRange = "Custom Range",
}

export enum DatePeriodStorageKey {
  StartDate = "start-date-range",
  EndDate = "end-date-range"
}

export const FIRST_DATE: Date = new Date(2021, 0, 1);
