import { FC } from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import "./datePicker.scss";
import { useTranslation } from "react-i18next";
import th from "date-fns/locale/th";
import dayjs from "dayjs";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";

interface IDatePickerProps {
  setDate: (selectDate: Date) => void;
  selectDate?: Date;
  showTimeSelect?: boolean;
  disabled?: boolean;
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  dateFormat?: string;
  dateTimeFormat?: string;
  displayBuddhistYear?: boolean;
  showIcon?: boolean;
  showTimeInput?: boolean;
  timeInputLabel?: string;
}

const CustomDatePicker: FC<IDatePickerProps> = ({
  setDate,
  selectDate,
  showTimeSelect = true,
  disabled = false,
  minDate,
  maxDate,
  dateFormat,
  displayBuddhistYear = false,
  showIcon = true,
  showTimeInput = false,
  timeInputLabel,
}) => {
  const { t, i18n } = useTranslation();
  registerLocale("th", th);
  const onDateChange = (date: Date | [Date, Date] | null) => {
    if (date) setDate(date as Date);
  };
  const buddhistYear: number = 543;
  const setDisplayFormat = (showTime: boolean) => {
    const localDateFormat = dateFormat ?? "dd-MM-yyyy";
    const localDateTimeFormat = dateFormat ?? "dd-MM-yyyy hh:mm aa";

    return showTime ? localDateTimeFormat : localDateFormat;
  };
  const displayDate = () => {
    if (selectDate) {
      const date: Date = displayBuddhistYear
        ? new Date(
            selectDate.getFullYear() + buddhistYear,
            selectDate.getMonth(),
            selectDate.getDate(),
            selectDate.getHours(),
            selectDate.getMinutes(),
            0
          )
        : selectDate;
      return (
        <span className="displayText">
          {dayjs(date).format(
            showTimeSelect || showTimeInput
              ? "DD-MM-YYYY HH:mm"
              : "DD-MM-YYYY"
          )}
        </span>
      );
    }

    return <span></span>;
  };

  const months = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];

  const monthsEn = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div className={disabled ? "date-picker disabled" : "date-picker"}>
      {showIcon && <CalendarMonthOutlinedIcon className="icon" />}
      {!showIcon && <div className="icon-empty"></div>}
      <DatePicker
        className="input"
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={false}
        selected={selectDate}
        onChange={(date) => onDateChange(date)}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        todayButton={t("TODAY")}
        locale={i18n.language}
        dateFormat={setDisplayFormat(showTimeSelect || showTimeInput)}
        customInput={displayDate()}
        timeInputLabel={timeInputLabel}
        showTimeInput={showTimeInput}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          decreaseYear,
          increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled,
        }) => (
          <div className="react-datepicker__current-month">
            <div>
              <button
                className="react-datepicker__custom-icon"
                type="button"
                onClick={decreaseYear}
                disabled={prevYearButtonDisabled}
              >
                <FiChevronsLeft />
              </button>
              <button
                className="react-datepicker__custom-icon"
                type="button"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              >
                <FiChevronLeft />
              </button>
            </div>
            <span>
              {i18n.language === "th"
                ? months[dayjs(date).month()]
                : monthsEn[dayjs(date).month()]}{" "}
              {dayjs(date).year() + (displayBuddhistYear ? buddhistYear : 0)}
            </span>

            <div>
              <button
                className="react-datepicker__custom-icon"
                type="button"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              >
                <FiChevronRight />
              </button>
              <button
                className="react-datepicker__custom-icon"
                type="button"
                onClick={increaseYear}
                disabled={nextYearButtonDisabled}
              >
                <FiChevronsRight />
              </button>
            </div>
          </div>
        )}
      ></DatePicker>
    </div>
  );
};

export default CustomDatePicker;
