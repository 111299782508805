import { ChartOptions } from "chart.js";
import i18n from "../localization/config";

const t = i18n.t.bind(i18n);

const CHART_FONT_FAMILY = "'Prompt', 'Kanit', sans-serif";

export const CHART_DEFAULT_FONT = {
  family: CHART_FONT_FAMILY,
  size: 12,
  weight: "400",
};

export const CHART_TITLE_FONT = {
  family: CHART_FONT_FAMILY,
  size: 16,
  weight: "500",
};

export const COLOR_CHART = [
  "#009FF5",
  "#855FF7",
  "#FF5792",
  "#F75F5F",
  "#FFC457",
  "#2DF4B9",
  "#52C2FF",
  "#A68BF9",
  "#FF85AF",
  "#F98B8B",
  "#FFD485",
  "#5AF6C8",
  "#80D2FF",
  "#C8B6FB",
  "#FFB3CD",
  "#FBB6B6",
  "#FFE4B3",
  "#86F9D6",
  "#E0F4FF",
  "#E9E2FD",
  "#FFE0EB",
  "#FDE2E2",
  "#FFF4E0",
  "#B1FBE5",
];

export const defaultBarChartOption: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    title: {
      display: true,
      text: t("MACHINE_MAINTANANCE_BARCHART_TITLE"),
      padding: {
        top: 20,
        bottom: 30,
      },
      font: CHART_TITLE_FONT,
    },
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 20,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      stacked: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
};
