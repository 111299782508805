import i18n from '../localization/config';

export const getCurrentLanguage = () => {
  const language = window.localStorage.getItem('abc_i18next');
  return language ?? "en";
};

export const setCurrentLanguage = (lang: string) => {
  window.localStorage.setItem('abc_i18next', lang);
};

export const translation = () =>{
  const lang = getCurrentLanguage();
  console.log(lang)
  i18n.changeLanguage(lang);
  const t = i18n.t.bind(i18n);

  return t;
}