import { BsFillGridFill } from "react-icons/bs";
import useProfile from "../../hooks/useProfile";
import { ProfileFactoryRole } from "../../Interfaces/profile";
import { getUserToken } from "../../utils/authUtil";
import { PATH } from "../../constants/path";
import client from "../../utils/axios";
import { useEffect } from "react";
import { Image } from "react-bootstrap";
import "./bottomSidebarItem.scss";

const BottomSidebarItem = () => {
  const user = getUserToken();
  const { profile } = useProfile();

  useEffect(() => {
    getImageOrganization();
  }, [profile]);

  const getSelectFactory = () => {
    if (
      profile &&
      profile.userFactory &&
      user != null &&
      user.selected_factory
    ) {
      const factoryRole = profile.userFactory.find(
        (value: ProfileFactoryRole) =>
          (value.factory?.id ?? 0) === Number(user.selected_factory)
      );
      if (factoryRole) {
        return factoryRole.factory?.name;
      }
    }
    return "";
  };

  const getImageOrganization = () => {
    client().get("v1/profile");
  };

  const checkProfileOrganization = () => {
    if (Number(user?.selected_factory) >= 1) {
      if (profile?.organization?.picture) {
        return (
          <label className="custom-file-upload-organization">
            <div className="img-preview-upload-organization">
              <Image
                src={profile?.organization?.picture}
                alt=""
                width={50}
                height={50}
              />
            </div>
          </label>
        );
      }
      return <BsFillGridFill color="#6764EA" />;
    }
    return <BsFillGridFill color="#6764EA" />;
  };
  return (
    <div
      className="bottom"
      onClick={() => (window.location.href = PATH.SELECT_FACTORY)}
    >
      {checkProfileOrganization()}
      <span>{getSelectFactory()}</span>
    </div>
  );
};

export default BottomSidebarItem;
