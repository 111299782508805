import { FC, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import { MachineEfficiencyExportParams } from "../../constants/machineEfficiency";
import { EfficiencyDetail } from "../../Interfaces/machine";
import client from "../../utils/axios";
import ButtonExportData from "../buttonExportData/buttonExportData";
import { useTranslation } from "react-i18next";

interface IMachineEfficiencyReport {
  reportParams: MachineEfficiencyExportParams;
  prepareReportData: (reportData: EfficiencyDetail[]) => void;
  downloadUrl: string;
  csvHeader: any;
  fileNameExport: () => string;
}

const MacchineEfficiencyReportButton: FC<IMachineEfficiencyReport> = ({
  reportParams,
  prepareReportData,
  downloadUrl,
  csvHeader,
  fileNameExport,
}) => {
  const { t } = useTranslation();
  const csvInstance = useRef<
    (CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }) | null
  >(null);
  const [csvData, setCsvData] = useState<any>([]);

  useEffect(() => {
    if (csvData.length > 0) {
      csvInstance.current?.link.click();
    }
  }, [csvData]);

  const onClickDownloadFile = () => {
    client()
      .get<EfficiencyDetail[]>(downloadUrl, {
        params: reportParams,
      })
      .then((res) => {
        if (res.data.length === 0) {
          toast.error(t("REPORT_DOWNLOAD_NO_DATA"));
        } else {
          const reportData = prepareReportData(res.data);
          setCsvData(reportData);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <>
      <CSVLink
        target="_blank"
        data={csvData}
        headers={csvHeader}
        ref={csvInstance}
        filename={fileNameExport()}
      />
      <ButtonExportData onClickDownloadFile={onClickDownloadFile} />
    </>
  );
};

export default MacchineEfficiencyReportButton;
