import React from "react";
import { Card } from "react-bootstrap";
import "./cardLayout.scss";

export interface CardLayoutProps {
  margin?: number;
  padding?: number;
  color?: string;
  children: React.ReactNode;
}

const CardLayout: React.FC<CardLayoutProps> = ({
  margin = 1,
  color,
  padding = 15,
  children,
}) => {
  return (
    <Card
      className={`card-layout-content my-${margin} ${color}`}
      style={{ padding: padding }}
    >
      {children}
    </Card>
  );
};

export default CardLayout;
