export enum FactoryRoleEnum {
    SUPER_ADMIN = 1,
    ADMIN = 2,
    MEMBER = 3,
}

export enum OwnerRoleEnum {
    OWNER = 1,
    MEMBER = 2,
}
