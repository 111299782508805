import { useEffect, useState } from "react";
import Navbar from "../components/navbar/navbar";
import Sidebar, { SidebarProps } from "../components/sidebar/sidebar";
import useMediaQuery from "@mui/material/useMediaQuery";
import "react-datepicker/dist/react-datepicker.css";
import { Outlet } from "react-router-dom";

const MainLayout = () => {
  const APP_VERSION = "1.0.0";
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const isSidebar = useMediaQuery("(max-width:820px)");

  useEffect(() => {
    if (isSidebar) {
      setSidebarCollapsed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const toggleSidebar = (): void => {
    setSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarProps: SidebarProps = {
    appVersion: APP_VERSION,
    isSidebarCollapsed,
  };

  return (
    <>
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="app">
        <Sidebar {...sidebarProps} />
        <div className="app-container">
          <Outlet />
        </div>
      </div>
    </>
  );
};
export default MainLayout;
