import { isEmpty, isNull } from "lodash";
import { Container, Form, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select, { SingleValue } from "react-select";
import DatePeriodPicker from "../../components/datePicker/datePeriodPicker";
import BreadcrumbBox, {
  BreadcrumbItem,
} from "../../components/breadcrumb/breadcrumb";
import { PATH } from "../../constants/path";
import {
  EfficiencyChillerDetail,
  EfficiencyDetail,
  EfficiencyReportDetail,
  Machine,
} from "../../Interfaces/machine";
import "../../components/customDropdown/customDropdown.scss";
import "./machineEfficiencyPage.scss";
import { fileExport } from "../../utils/fileExportUtil";
import { toLocal } from "../../utils/dateTimeUtil";
import { getScheduleStatus } from "../../utils/statusUtil";
import LineChart from "../../components/lineChart/lineChart";
import { CHART_TITLE_FONT } from "../../constants/chartConstants";
import useMachineEfficiency from "../../hooks/useMachineEfficiency";
import { MACHINE_EFFIECIENCY_CSV_HEADER } from "../../constants/machineEfficiency";
import MacchineEfficiencyReportButton from "../../components/csvButton/machineEfficiencyExportButton";
import { getChartLabels, getChartValues } from "../../utils/chart";
import { ChartOptions } from "chart.js";
import { useEffect, useState } from "react";
import { machineTypeEnum } from "../../enum/machineType";
import { EfficiencyInterface } from "../../Interfaces/schedule";
import { ChartLabelBuilder, ChartValueBuilder } from "../../Interfaces/dashboard";

const MachinceEfficiencyPage = (): JSX.Element => {
  const { t } = useTranslation();
  const [machineTypeUnit, setMachineTypeUnit] = useState<string>("");
  const {
    machineLabel,
    machines,
    setMachineLabel,
    machineId,
    setMachineId,
    startDate,
    endDate,
    setStartDateState,
    setEndDateState,
    efficiencyReport,
    isReportLoad,
    isMachineLoading,
    selectedMachine,
    setSelectedMachineState,
    minDate,
    maxDate
  } = useMachineEfficiency();

  const defaultLineChartOption: ChartOptions<"line"> = {
    elements: {
      point: {
        radius: 5,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `${t("MACHINE_EFFICIENCY_LINECHART_TITLE")} (${machineTypeUnit})`,
        padding: {
          top: 20,
          bottom: 30,
        },
        font: CHART_TITLE_FONT,
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          padding: 20,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        suggestedMax: 100,
        suggestedMin: 0,
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  useEffect(() => {
    if (Number(selectedMachine?.machineTypeId) === machineTypeEnum.CHILLER) {
      const unit = "COP";
      setMachineTypeUnit(unit);
    } else {
      const unit = "%";
      setMachineTypeUnit(unit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMachine]);

  const getBreadcrumData = (): BreadcrumbItem[] => {
    const crump: BreadcrumbItem[] = [
      {
        label: t("MENU_REPORT"),
      },
      {
        label: t("MENU_EFFICIENCY"),
        href: PATH.EFFICIENCY,
      },
    ];

    return crump;
  };

  const prepareEfficiencyChiller = (
    efficiencyChiller: EfficiencyChillerDetail[]
  ) => {
    let result = "";

    for (const key in efficiencyChiller) {
      result += `${key} ${efficiencyChiller[key].toString()},`;
    }

    return result.substring(result.length - 1, 1);
  };

  const prepareReportData = (reportData: EfficiencyDetail[]) => {
    const returnData: EfficiencyReportDetail[] = [];

    reportData.forEach((report) => {
      returnData.push({
        ...report,
        date: toLocal(report.date).format("DD/MM/YYYY HH:mm:ss"),
        status: getScheduleStatus(report.status),
        efficiencyChiller: !isNull(report.efficiencyChiller)
          ? prepareEfficiencyChiller(report.efficiencyChiller)
          : null,
      });
    });

    return returnData;
  };

  const handleSelectMachine = (selectedValue: SingleValue<Machine>): void => {
    const findMachine = machines.find(
      (label) => label.id === selectedValue?.id
    );

    if (findMachine) {
      setSelectedMachineState(findMachine);
      setMachineLabel(findMachine.name);
      setMachineId(findMachine.id);
    }
  };

  const fileNameExport = (): string => {
    const find = machines?.filter((item) => item.id === machineId);

    if (find) {
      const machine = find.map((item) => item.name);

      return fileExport(
        t("TITLE_REPORT_EFFICIENCY"),
        machine,
        startDate,
        endDate
      );
    }

    return "";
  };

  const renderMachineDropdown = (): JSX.Element => {
    if (!isEmpty(machines)) {
      return (
        <Select
          className="custom-dropdown text-body3 "
          classNamePrefix="custom-dropdown"
          options={machines}
          defaultValue={selectedMachine}
          getOptionLabel={(option: Machine) => option.name}
          getOptionValue={(option: Machine) => option.id.toString()}
          onChange={(selected: SingleValue<Machine>) =>
            handleSelectMachine(selected)
          }
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      );
    }

    return <></>;
  };

  const mapToChartData = (data: EfficiencyInterface[]) => {
    if (data === null) {
      return [];
    }

    const chartValue : ChartValueBuilder[] = data.map((e) => ({
      date: e.date,
      value: e.value,
    }));

    const chartLabel : ChartLabelBuilder[] = data.map((e) => ({
      groupTypeString:  e.groupTypeString,
    }));

    return [ chartLabel, chartValue ]
  };

  const prepareLineChartData = () => {
    const [ chartLabel, chartValue ] = mapToChartData(efficiencyReport);

    const { labels, baseDataKey } = getChartLabels(
      startDate,
      endDate,
      chartLabel as ChartLabelBuilder[]
    );

    const dataEfficiencyReport = {
      labels,
      datasets: [
        {
          label: machineLabel,
          data: getChartValues(chartValue as ChartValueBuilder[], baseDataKey),
          borderColor: "#24B2FF",
          backgroundColor: "#24B2FF",
        },
      ],
    };

    return dataEfficiencyReport;
  };

  return (
    <Container>
      <BreadcrumbBox items={getBreadcrumData()}></BreadcrumbBox>
      <>
        <Form className="form-efficinecy">
          <div className="report-header">
            <Form.Group className="report-header__group" controlId="formEmail">
              <Form.Label>{t("MACHINE_TABLE_NAME")}</Form.Label>
              {isMachineLoading ? (
                <div>
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                renderMachineDropdown()
              )}
            </Form.Group>
            <Form.Group className="report-header__date" controlId="formRole">
              <Form.Label>{t("SCHEDULE_LOG_DATE")}</Form.Label>
              <DatePeriodPicker
                fromDate={startDate}
                toDate={endDate}
                setFromDate={setStartDateState}
                setToDate={setEndDateState}
                minDate={minDate}
                maxDate={maxDate}
              ></DatePeriodPicker>
            </Form.Group>
          </div>
          <div className="button-content">
            <MacchineEfficiencyReportButton
              reportParams={{
                machineId: machineId,
                from: startDate,
                to: endDate,
              }}
              downloadUrl="/v1/report/efficiency-report/export"
              csvHeader={MACHINE_EFFIECIENCY_CSV_HEADER}
              prepareReportData={prepareReportData}
              fileNameExport={fileNameExport}
            />
          </div>
          <div>
            <LineChart
              isLoading={isReportLoad}
              data={prepareLineChartData()}
              options={defaultLineChartOption}
            />
          </div>
        </Form>
      </>
    </Container>
  );
};

export default MachinceEfficiencyPage;
