export enum RecordStatus {
    NORMAL = 0,
    UNDER = 1,
    OVER = 2,
    MISSED = 3,
}

export enum ScheduleStatus {
    PENDING = 1,
    CHACKED = 2,
    MISSED = 3,
    
}
  
export enum RecordTypeEnum {
    RECOED = 1,
    MAINTANANCE = 2,
}

export enum MaintenanceStatus {
    CHECK = 1,
    MISSED = 2,
}

export enum RecordTypeNameEnum {
    INPUT_FORM = "record_type_input_form",
    SNAP_SHOT= "record_type_snap_shot",
}