import { t } from "i18next";
import {
  MaintenanceStatus,
  RecordStatus,
  RecordTypeEnum,
  ScheduleStatus,
} from "../enum/reportEnum";

export const getStatus = (status: number, recodeType: number) => {
  switch (status) {
    case RecordStatus.NORMAL:
      return t("REPORT_MIDDLE");
    case RecordStatus.UNDER:
      return t("REPORT_UNDER");
    case RecordStatus.OVER:
      return t("REPORT_OVER");
    case RecordStatus.MISSED:
      if (recodeType === RecordTypeEnum.RECOED) {
        return t("REPORT_MISS_RECORD");
      }
      return t("REPORT_MISS_MAINTANANCE");
  }
  return status;
};

export const getScheduleStatus = (status: number) => {
  switch (status) {
    case ScheduleStatus.CHACKED:
      return t("REPORT_RECORD");
    case ScheduleStatus.PENDING:
      return t("REPORT_PENDING");
    case ScheduleStatus.MISSED:
      return t("REPORT_MISS_RECORD");
  }
  return status;
};

export const getMaintenanceStatus = (maintenanceStatus: number) => {
  switch (maintenanceStatus) {
    case MaintenanceStatus.CHECK:
      return t("MAINTENANCE_PASSED");
    case MaintenanceStatus.MISSED:
      return t("MAINTENANCE_MISSED");
  }
  return maintenanceStatus;
};
