export enum machineTypeEnum {
  AIR_COMPRESSOR = 1,
  BOILER = 2,
  CHILLER = 3,
  OTHER = 4,
}

export enum GaugeType {
  WATER_FLOW_RATE = 9,
  FUEL_FLOW_RATE = 10,
  BLOWDOWN_TIME = 44,
  PIPE_D = 45,
}

